import Theme from '@mediashop/app/Theme';
import './pattern/template/Meta/index.tsx';
import icons from './icons';
import VerticalBrandsTheme from '@mediashop/vertical-brands-base';

const HammersmithTheme: Theme = {
    name: 'hammersmith',
    extends: VerticalBrandsTheme,
    icons,
};

export default HammersmithTheme;

import { ReactComponent as ArrowLeft } from './assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from './assets/icons/arrow-right.svg';
import { ReactComponent as ArrowDown } from './assets/icons/arrow-down.svg';
import { ReactComponent as Trash } from './assets/icons/trash.svg';
import { ReactComponent as Pencil } from './assets/icons/pencil.svg';
import { ReactComponent as Share } from './assets/icons/share.svg';
import { ReactComponent as AddToCart } from './assets/icons/add-to-cart.svg';
import { ReactComponent as BurgerMenu } from './assets/icons/burgermenu.svg';
import { ReactComponent as BurgerMenuClose } from './assets/icons/burgermenu-close.svg';
import { ReactComponent as AccountVerticalBrand } from './assets/icons/account.svg';
import { ReactComponent as AccountFilledVerticalBrand } from './assets/icons/account-filled.svg';
import { ReactComponent as CartVerticalBrand } from './assets/icons/cart.svg';
import { ReactComponent as ChevronDown } from './assets/icons/chevron_down.svg';

export default {
    AccountVerticalBrand,
    AccountFilledVerticalBrand,
    AddToCart,
    ArrowDown,
    ArrowDownFilled: ChevronDown,
    ArrowLeft,
    ArrowRight,
    ChevronDown,
    CartVerticalBrand,
    BurgerMenu,
    BurgerMenuClose,
    Pencil,
    Share,
    Trash,
};
